<template>
  <div class="">
    <!-- 轮播图 -->
    <div class="banner">
      <a id="banner" style="position:absolute;top: -76px;"></a>
      <div class="cover">
        <div class="cover-title">灵活用工，开启职场新航道 成就你的无限可能</div>
      </div>
      <el-carousel indicator-position="outside" style="height: 708px">
        <el-carousel-item v-for="(item, index) in banner" :key="index" style="height: 708px">
          <img :src="item.url" alt="Carousel Image" style="width: 100%; height: 100%" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 灵活用工 -->
    <div class="work">
      <div class="work-con">
        <div class="work-btn">
          <div
            @click="changeWork(0)"
            :class="workIndex == 0 ? 'work-active' : ''"
            class="work-btns"
          >
            灵活用工 活力无限
          </div>
          <div
            @click="changeWork(1)"
            :class="workIndex == 1 ? 'work-active' : ''"
            class="work-btns"
          >
            灵活就业 创赢未来
          </div>
        </div>
        <div class="work-mid">
          <div class="work-mid-left">
            <el-image
              style="width: 716px; height: 386px"
              :src="workList[workItemIndex].article_img[0]"
            ></el-image>
            <div class="work-mid-ti">
              <div class="work-mid-ti-title">{{ workList[workItemIndex].title }}</div>
              <div class="work-mid-ti-zhai">
                {{ workList[workItemIndex].introduction }}
              </div>
            </div>
          </div>
          <div class="work-mid-right">
            <div
              class="work-mid-right-row"
              :class="workItemIndex == index ? 'work-mid-right-row-active' : ''"
              v-for="(item, index) in workList"
              :key="index"
              @click="goToNewsDetail(item.article_id)"
              @mouseenter="hoverWork(index)"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
        <div class="work-bottom">
          <div class="work-bottom-more" @click="newsMore(workIndex + 1)">查看更多</div>
        </div>
      </div>
    </div>
    <!-- 行业新闻 -->
    <div class="news">
      <a id="news" style="position:absolute;top: -76px;"></a>
      <div class="news-con">
        <div class="news-title">行业新闻</div>
        <div class="news-box">
          <div class="news-list">
            <div
              class="news-row"
              :class="newsItemIndex == index ? 'news-row-active' : ''"
              v-for="(item, index) in newsList"
              :key="index"
              @click="goToNewsDetail(item.article_id)"
              @mouseenter="hoverNews(index)"
            >
              {{ item.title }}
            </div>
          </div>
          <div class="news-right" v-if="newsList.length > 0">
            <div class="news-right-img">
              <el-image
                style="width: 620px; height: 325px"
                :src="newsList[newsItemIndex].article_img[0]"
              ></el-image>
            </div>
            <div class="news-mid-ti">
              <div class="news-mid-ti-title">{{ newsList[newsItemIndex].title }}</div>
              <div class="news-mid-ti-zhai">
                {{ newsList[newsItemIndex].introduction }}
              </div>
            </div>
          </div>
        </div>
        <div class="work-bottom">
          <div class="work-bottom-more" @click="newsMore(3)">查看更多</div>
        </div>
      </div>
    </div>
    <!-- 税务规划服务 -->
    <div class="tax">
      <a id="tax" style="position:absolute;top: -76px;"></a>
      <div class="tax-con">
        <div class="tax-con-title">税务规划服务</div>
        <div class="tax-con-content">
          税务规划服务，为企业与个人定制专业方案。专业团队依据税收政策，精准评估风险，挖掘优惠机遇。优化税务结构，降低成本负担，确保合法合规，助力实现财富增值与稳健发展。
        </div>
      </div>
    </div>
    <!-- 营业执照 -->
    <div class="license">
      <div class="license-box">
        <el-image
          style="width: 392px; height: 279px"
          :src="require('@/assets/ying.png')"
        ></el-image>
      </div>
      <div class="license-box">
        <el-image
          style="width: 186px; height: 270px"
          :src="require('@/assets/zheng.png')"
        ></el-image>
      </div>
    </div>
    <!-- 主要服务对象 -->
    <div class="main">
      <div class="main-con">
        <div class="main-title">主要服务对象</div>
        <div class="main-content">
          灵活用工打造灵动的职场生态。企业不再受固定用工模式的限制，能够更加敏捷地应对市场变化。求职者也不再局限于单一的职业路径，可根据自己的兴趣和能力选择不同的工作任务，享受更加自由、富有创造力的工作体验，共同迈向灵动的职场新未来。
        </div>
        <div class="main-btn">
          <div
            @click="changeMain(0)"
            :class="mainIndex == 0 ? 'main-btn-text-active' : ''"
            class="main-btn-text"
          >
            创意工作者
          </div>
          <div
            @click="changeMain(1)"
            :class="mainIndex == 1 ? 'main-btn-text-active' : ''"
            class="main-btn-text"
          >
            文字工作者
          </div>
          <div
            @click="changeMain(2)"
            :class="mainIndex == 2 ? 'main-btn-text-active' : ''"
            class="main-btn-text"
          >
            技术开发者
          </div>
          <div
            @click="changeMain(3)"
            :class="mainIndex == 3 ? 'main-btn-text-active' : ''"
            class="main-btn-text"
          >
            学生群体
          </div>
          <div
            @click="changeMain(4)"
            :class="mainIndex == 4 ? 'main-btn-text-active' : ''"
            class="main-btn-text"
          >
            上班族
          </div>
          <div
            @click="changeMain(5)"
            :class="mainIndex == 5 ? 'main-btn-text-active' : ''"
            class="main-btn-text"
          >
            家庭成员
          </div>
          <div
            @click="changeMain(6)"
            :class="mainIndex == 6 ? 'main-btn-text-active' : ''"
            class="main-btn-text"
          >
            企业项目型用工
          </div>
          <div
            @click="changeMain(7)"
            :class="mainIndex == 7 ? 'main-btn-text-active' : ''"
            class="main-btn-text"
          >
            季节性用工
          </div>
          <div
            @click="changeMain(8)"
            :class="mainIndex == 8 ? 'main-btn-text-active' : ''"
            class="main-btn-text"
          >
            临时替代用工
          </div>
        </div>
        <div class="main-box">
          <div class="main-box-img">
            <el-image class="main-img" :src="mainList[mainIndex].url"></el-image>
          </div>
          <div class="main-bot">
            <div class="main-box-text">{{ mainList[mainIndex].title }}</div>
            <div class="main-box-con">
              {{ mainList[mainIndex].cont }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 系统工具 -->
    <div class="tool">
      <div class="tool-title">系统工具</div>
      <el-tabs v-model="activeTool" @tab-click="handleToolClick" class="tool-tabs">
        <el-tab-pane label="系统服务平台" name="0"></el-tab-pane>
        <el-tab-pane label="微信公众号" name="1"></el-tab-pane>
      </el-tabs>
      <el-carousel height="566px" :autoplay="false" :initial-index="toolIndex" ref="tool">
        <el-carousel-item name="0">
          <div class="tool-box">
            <el-image
              style="width: 620px; height: 350px"
              :src="require('@/assets/web.png')"
            ></el-image>
            <div class="tool-btn">进入系统服务平台</div>
          </div>
        </el-carousel-item>
        <el-carousel-item name="1">
          <div class="tool-box">
            <el-image
              style="width: 350px; height: 350px"
              :src="require('@/assets/wx.jpg')"
            ></el-image>
            <div class="tool-btn">扫码关注公众号</div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 开启用工标准 -->
    <div class="open">
      <div class="open-con">
        <div class="open-title">开启灵活用工，绽放职场精彩</div>
        <div class="open-cont">
          <div class="open-item">
            <div class="open-item-title">降低用工成本</div>
            <div class="open-item-content">
              企业可以免去正式员工的一些额外开支，如各项补助金、公积金、交通费补助、租房住房补助、电话费补助等。同时，灵活用工模式下企业无需为灵活用工人员缴纳社保，能节省大量人力成本。例如，企业聘用一个灵活用工人员比聘用正式员工可节约接近
              35% 的成本。
            </div>
          </div>
          <div class="open-item">
            <div class="open-item-title">优化管理成本</div>
            <div class="open-item-content">
              灵活就业人员费用结算程序繁琐且发放不固定，加上对正式员工的培训流程，管理成本较高。而灵活用工
              “即时即用” 的特性，可大大缩减企业的人才管理成本。
            </div>
          </div>
          <div class="open-item">
            <div class="open-item-title">满足业务需求</div>
            <div class="open-item-content">
              能有效满足企业的工作流程需求。企业用工需求可能存在季节性、临时性或阶段性的变化，例如一天中会有用工的高峰期和低谷期，灵活用工可以根据业务变化灵活地调整用工数量，解决企业的用工波动问题，提高运营效率。当正式员工因病事假、生育假等短暂性事件而无法工作时，也可以由灵活用工人员及时弥补，避免了员工复职时需解雇替代品的麻烦。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 企业介绍 -->
    <div class="enterprise">
      <a id="enterprise" style="position:absolute;top: -76px;"></a>
      <div class="enterprise-title">企业介绍</div>
      <el-tabs v-model="activeEnterprise" @tab-click="handleEnterpriseClick" class="enterprise-tab">
        <el-tab-pane label="关于税务规划" name="0"></el-tab-pane>
        <el-tab-pane label="关于我们" name="1"></el-tab-pane>
        <el-tab-pane label="联系我们" name="2"></el-tab-pane>
        <el-tab-pane label="加入我们" name="3"></el-tab-pane>
      </el-tabs>
      <el-carousel
        height="737px"
        :autoplay="false"
        :initial-index="enterpriseIndex"
        ref="enterprise"
      >
        <el-carousel-item
          v-for="(item, index) in enterpriseList"
          :key="index"
          :name="index.toString()"
        >
          <div class="enterprise-content">
            <el-image style="width: 1000px; height: 570px" :src="item.url"></el-image>
            <div class="enterprise-zhai">
              {{ item.title }}
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 预约咨询 -->
    <div class="consult">
      <a id="consult" style="position:absolute;top: -76px;"></a>
      <div class="consult-con">
        <div class="consult-title">预约咨询</div>
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          label-width="100px"
          class="demo-form"
          label-position="top"
        >
          <el-row :gutter="60">
            <el-col :span="8">
              <el-form-item label="姓名" prop="name">
                <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电话号码" prop="mobile">
                <el-input v-model="form.mobile" placeholder="请输入电话号码"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="企业名称" prop="company">
                <el-input v-model="form.company" placeholder="请输入企业名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="预约回访时间" prop="yu_date">
                <el-radio-group v-model="form.yu_dates">
                  <el-radio :label="dateList[0].date" :value="dateList[0].date"></el-radio>
                  <el-radio :label="dateList[1].date" :value="dateList[1].date"></el-radio>
                </el-radio-group>
                <div class="line">
                  <div
                    @click="changeTime('09:00-11:00')"
                    :class="form.time == '09:00-11:00' ? 'lin-btn-active' : ''"
                    class="line-btn"
                  >
                    09:00-11:00
                  </div>
                  <div
                    @click="changeTime('11:00-13:00')"
                    :class="form.time == '11:00-13:00' ? 'lin-btn-active' : ''"
                    class="line-btn"
                  >
                    11:00-13:00
                  </div>
                  <div
                    @click="changeTime('13:00-15:00')"
                    :class="form.time == '13:00-15:00' ? 'lin-btn-active' : ''"
                    class="line-btn"
                  >
                    13:00-15:00
                  </div>
                  <div
                    @click="changeTime('15:00-17:00')"
                    :class="form.time == '15:00-17:00' ? 'lin-btn-active' : ''"
                    class="line-btn"
                  >
                    15:00-17:00
                  </div>
                  <div
                    @click="changeTime('17:00-19:00')"
                    :class="form.time == '17:00-19:00' ? 'lin-btn-active' : ''"
                    class="line-btn"
                  >
                    17:00-19:00
                  </div>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="所在城市" prop="citys">
                <el-cascader
                  placeholder="请选择所在城市"
                  v-model="form.citys"
                  :options="dataCity"
                  :props="{ expandTrigger: 'hover', value: 'label' }"
                  style="width: 100%"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="业务场景" prop="remark">
                <el-input
                  :autosize="{ minRows: 4, maxRows: 4 }"
                  type="textarea"
                  v-model="form.remark"
                  placeholder="请输入业务场景需求，我们将根据您提供的业务场景需求来提供相应的业务介绍"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <el-button size="small" type="primary" class="line-btns" @click="submitForm('form')"
              >确认提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
const dataCity = require("@/common/city.json");
import { articlelist, addappo } from "@/api/index";
import { getTomorrowAndDayAfterTomorrow } from "@/common/common";
export default {
  data() {
    return {
      dataCity,
      dateList: getTomorrowAndDayAfterTomorrow(),
      banner: [
        {
          url: require("@/assets/banner.gif"),
        },
      ],
      activeName: "first",
      form: {
        time: "09:00-11:00",
        yu_dates: getTomorrowAndDayAfterTomorrow()[0].date,
      },
      isShowAll: 1,
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        mobile: [
          {
            required: true,
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        address_one: [{ required: true, message: "请输入地址1", trigger: "blur" }],
        company: [{ required: true, message: "请输入企业名称", trigger: "blur" }],
        yu_dates: [{ required: true, message: "请选择预约回访时间", trigger: "change" }],
        citys: [{ required: true, message: "请选择所在城市", trigger: "change" }],
        remark: [{ required: true, message: "请输入业务场景", trigger: "blur" }],
      },
      activeEnterprise: "0", // 企业介绍
      enterpriseIndex: 0, // 企业介绍
      enterpriseList: [
        {
          id: 0,
          url: require("@/assets/prise/per1.png"),
          title:
            "税务规划是在合法合规前提下，对企业或个人税务进行精心筹划。通过分析税收政策、优化业务流程、合理安排收支等方式，降低税务负担，提升经济效益，实现税务成本最小化与财富价值最大化的有效手段。",
        },
        {
          id: 1,
          url: require("@/assets/prise/per2.png"),
          title:
            "安庆中嘉网络科技有限公司成立于2023年11月29日，经营范围包括一般项目：技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；网络技术服务；信息技术咨询服务；社会经济咨询服务；网络与信息安全软件开发；企业管理；居民日常生活服务；外卖递送服务；承接档案服务外包；装卸搬运；房地产经纪；市场营销策划；创业空间服务；人力资源服务。",
        },
        {
          id: 2,
          url: require("@/assets/prise/per3.png"),
          title:
            "安徽省安庆市望江县经济开发区望江大道58号1号楼102室",
        },
        {
          id: 3,
          url: require("@/assets/prise/per4.png"),
          title:"你是否渴望挑战自我、成就非凡？加入我们，一起在奋斗的道路上绽放光彩。这里有广阔的舞台，等你展现才华；这里有优秀的团队，与你并肩前行。快来加入我们吧！",
        },
      ],
      activeTool: "0", //系统工具
      toolIndex: 0, // 系统工具轮播
      toolList: [
        {
          id: 0,
          url: require("@/assets/web.png"),
          title: "税务规划",
        },
        {
          id: 1,
          url: require("@/assets/web.png"),
          title: "税务规划",
        },
      ],
      mainIndex: 0, //主要服务对象
      mainList: [
        {
          id: 0,
          url: require("@/assets/serve/ser1.png"),
          title: "创意工作者",
          cont: "如设计师、摄影师、插画师等。他们通常凭借自身的专业技能独立承接项目，工作时间和地点灵活，能够通过灵活用工平台获得不同客户的订单，充分发挥自己的创造力，同时也能更好地平衡工作与生活。",
        },
        {
          id: 1,
          url: require("@/assets/serve/ser2.png"),
          title: "文字工作者",
          cont: "包括作家、编辑、自媒体人等。他们可以根据自己的创作节奏选择项目，不受传统工作时间的约束，为不同的媒体平台、企业或个人提供内容创作服务。",
        },
        {
          id: 2,
          url: require("@/assets/serve/ser3.png"),
          title: "技术开发者",
          cont: "如程序员、软件工程师等。这些专业人士可以在多个项目中发挥自己的技术才能，通过灵活用工参与不同企业的软件开发、系统维护等工作，提升自己的技术水平和项目经验。",
        },
        {
          id: 3,
          url: require("@/assets/serve/ser4.png"),
          title: "学生群体",
          cont: "许多大学生利用课余时间通过灵活用工平台寻找兼职工作，如家教、促销、客服等。这不仅可以赚取一定的收入，减轻家庭经济负担，还能积累工作经验，为未来的职业发展打下基础。",
        },
        {
          id: 4,
          url: require("@/assets/serve/ser5.png"),
          title: "上班族",
          cont: "一些有固定工作的人也会在业余时间从事兼职，以增加收入或拓展职业领域。例如，一位办公室文员可能在周末或晚上担任餐厅服务员、外卖配送员等兼职工作。",
        },
        {
          id: 5,
          url: require("@/assets/serve/ser6.png"),
          title: "家庭成员",
          cont: "他们可以在照顾家庭的同时，通过灵活用工参与一些适合的工作，如手工制作、数据录入、在线客服等，实现自我价值，增加家庭收入。",
        },
        {
          id: 6,
          url: require("@/assets/serve/ser7.png"),
          title: "企业项目型用工",
          cont: "当企业有特定的项目需要完成时，会招聘短期合同工。例如，一家企业在推出新产品时，可能需要招聘市场调研人员、活动策划人员等短期合同工，项目结束后合同即终止。",
        },
        {
          id: 7,
          url: require("@/assets/serve/ser8.png"),
          title: "季节性用工",
          cont: "一些行业存在明显的季节性需求，如农业、旅游业、电商行业等。在旺季时，企业会招聘大量的短期合同工来满足业务需求，如采摘工人、景区导游、快递员等。",
        },
        {
          id: 8,
          url: require("@/assets/serve/ser9.png"),
          title: "临时替代用工",
          cont: "当企业员工因生病、休假、离职等原因出现岗位空缺时，会招聘临时替代用工。这种用工方式可以保证企业的正常运营，避免因人员短缺而造成的损失。",
        },
      ],
      // 灵活用工
      workIndex: 0,
      workItemIndex: 0,
      workList: [],

      // 行业新新闻
      newsItemIndex: 0,
      newsList: [],
    };
  },
  created() {
    this.getNewsclelist();
    this.getworkclelist(1);
  },
  methods: {
    goToNewsDetail(id) {
      console.log(id,"sd")
      const url = this.$router.resolve({ path: "/detail", query: { id } }).href;
      window.open(url, "_blank");
    },
    // 切换时间
    changeTime(val) {
      this.form.time = val;
    },
    // 灵活用工 活力无限
    getworkclelist(num) {
      articlelist({
        cat_id: num,
      }).then((res) => {
        if (res.code == 1) {
          this.workList = res.data.list;
        }
      });
    },
    // 获取行业新闻数据
    getNewsclelist() {
      articlelist({
        cat_id: 3,
      }).then((res) => {
        if (res.code == 1) {
          this.newsList = res.data.list;
        }
      });
    },

    // 企业介绍切换
    handleEnterpriseClick(tab, event) {
      this.enterpriseIndex = Number(tab.name);
      this.$refs.enterprise.setActiveItem(Number(tab.name));
    },
    // 切换系统工具
    handleToolClick(tab, event) {
      this.toolIndex = Number(tab.name);
      this.$refs.tool.setActiveItem(Number(tab.name));
    },
    // 主要服务切换
    changeMain(num) {
      this.mainIndex = num;
    },
    // 灵活用工
    changeWork(num) {
      this.workIndex = num;
      this.workItemIndex = 0;
      this.getworkclelist(num + 1);
    },
    // 滑入标题
    hoverWork(num) {
      this.workItemIndex = num;
    },
    // 滑入新闻
    hoverNews(num) {
      this.newsItemIndex = num;
    },
    // 点击查看更多
    newsMore(num) {
      const url = this.$router.resolve({ path: "/news", query: { num } }).href;
      window.open(url, "_blank");
    },
    // 选择城市
    // handleChange1(value) {
    //   console.log(value, "Sd");
    //   let city = value[0] + value[1] + value[2]
    //   this.form.city = city;
    //   console.log(this.form.city,this.form)
    // },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = {
            ...this.form,
            yu_date: this.form.yu_dates + " " + this.form.time,
            city: this.form.citys[0] + this.form.citys[1] + this.form.citys[2],
          };
          delete form.time;
          delete form.yu_dates;
          delete form.citys;
          addappo({
            ...form,
          })
            .then((res) => {
              if (res.code == 1) {
                this.$message.success(res.msg);
                setTimeout(() => {
                  this.$refs[formName].resetFields();
                  this.form = {
                    time: "09:00-11:00",
                    yu_dates: getTomorrowAndDayAfterTomorrow()[0].date,
                  };
                }, 1000);
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.$message.error(err.msg);
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  /deep/.el-carousel__arrow {
    display: none !important;
  }
.banner {
  width: 100%;
  height: 708px;
  position: relative;
  /deep/.el-carousel__arrow {
    display: none !important;
  }
  .cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: rgba($color: #3067c9, $alpha: 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    .cover-title {
      font-size: 40px;
      width: 500px;
      color: #fff;
      font-weight: bold;
    }
  }
}
// 灵活用工
.work {
  width: 100%;
  height: 1016px;
  overflow: hidden;
  background: #fff;
  .work-con {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    .work-btn {
      width: 100%;
      margin: 85px 0 53px;
      height: 82px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .work-btns {
        width: 596px;
        height: 82px;
        border-radius: 4px;
        font-size: 36px;
        font-weight: bold;
        color: #999;
        line-height: 82px;
        margin: 0 4px;
        background: #f8f8f8;
        cursor: pointer;
        transition: all 0.6s;
      }
      .work-active {
        color: #fff;
        background: #3067c9;
      }
    }
  }
  .work-mid {
    width: 100%;
    height: 590px;
    display: flex;
    align-items: center;
    justify-content: center;
    .work-mid-left {
      height: 100%;
      width: 716px;
      margin-right: 68px;
      .work-mid-ti {
        width: 100%;
        height: 175px;
        box-sizing: border-box;
        padding-top: 56px;
        .work-mid-ti-title {
          font-size: 30px;
          color: #1a1a1a;
          text-align: left;
          margin-bottom: 22px;
          overflow: hidden; /* 隐藏超出的内容 */
          white-space: nowrap; /* 不换行 */
          text-overflow: ellipsis; /* 用省略号表示隐藏的内容 */
        }
        .work-mid-ti-zhai {
          font-size: 18px;
          color: #666;
          line-height: 28px;
          text-align: left;
        }
      }
    }
    .work-mid-right {
      height: 100%;
      width: 396px;
      overflow-y: scroll;
      /* 整体滚动条样式 */
      &::-webkit-scrollbar {
        width: 4px; /* 垂直滚动条的宽度 */
        height: 8px; /* 水平滚动条的高度 */
      }

      /* 滚动条轨道 */
      &::-webkit-scrollbar-track {
        background: #f5f5f5; /* 轨道颜色 */
      }

      /* 滚动条滑块 */
      &::-webkit-scrollbar-thumb {
        background: #e1e1e1; /* 滑块颜色 */
        border-radius: 4px; /* 滑块圆角 */
      }

      /* 滚动条按钮（上下箭头） */
      &::-webkit-scrollbar-button {
        display: none; /* 隐藏按钮 */
      }

      /* 滚动条角落 */
      &::-webkit-scrollbar-corner {
        background: #f1f1f1; /* 角落颜色 */
      }
      .work-mid-right-row {
        width: 100%;
        font-size: 18px;
        color: #666;
        overflow: hidden; /* 隐藏超出的内容 */
        white-space: nowrap; /* 不换行 */
        text-overflow: ellipsis; /* 用省略号表示隐藏的内容 */
        line-height: 44px;
        cursor: pointer;
        text-align: left;
      }
      .work-mid-right-row-active {
        color: #3067c9;
      }
    }
  }
  .work-bottom {
    width: 100%;
    height: 204px;
    overflow: hidden;
    .work-bottom-more {
      width: 150px;
      height: 44px;
      border-radius: 4px;
      font-size: 16px;
      color: #fff;
      line-height: 44px;
      text-align: center;
      margin: 60px auto;
      background: #3067c9;
      cursor: pointer;
    }
  }
}
// 行业新闻
.news {
  width: 100%;
  height: 1184px;
  background: #fcfcfc;
  position: relative;
  .news-con {
    width: 1200px;
    margin: 0 auto;
    height: 100%;
    overflow: hidden;
    .news-title {
      font-size: 36px;
      font-weight: bold;
      color: #1a1a1a;
      width: 100%;
      margin: 96px auto;
    }
    .news-box {
      width: 100%;
      height: 722px;
      display: flex;
      justify-content: center;
      .news-list {
        width: 450px;
        height: 100%;
        margin-bottom: 22px;
        overflow-y: scroll; /* 隐藏超出的内容 */
        /* 整体滚动条样式 */
        &::-webkit-scrollbar {
          width: 4px; /* 垂直滚动条的宽度 */
          height: 8px; /* 水平滚动条的高度 */
        }

        /* 滚动条轨道 */
        &::-webkit-scrollbar-track {
          background: #f5f5f5; /* 轨道颜色 */
        }

        /* 滚动条滑块 */
        &::-webkit-scrollbar-thumb {
          background: #e1e1e1; /* 滑块颜色 */
          border-radius: 4px; /* 滑块圆角 */
        }

        /* 滚动条按钮（上下箭头） */
        &::-webkit-scrollbar-button {
          display: none; /* 隐藏按钮 */
        }

        /* 滚动条角落 */
        &::-webkit-scrollbar-corner {
          background: #f1f1f1; /* 角落颜色 */
        }
        margin-right: 70px;
        .news-row {
          font-size: 18px;
          color: #666;
          line-height: 44px;
          text-align: left;
          overflow: hidden; /* 隐藏超出的内容 */
          white-space: nowrap; /* 不换行 */
          text-overflow: ellipsis; /* 用省略号表示隐藏的内容 */
          cursor: pointer;
        }
        .news-row-active {
          color: #3067c9;
        }
      }
      .news-right {
        width: 640px;
        height: 100%;
        .news-right-img {
          width: 100%;
          height: 345px;
          box-sizing: border-box;
          // padding: 10px;
          box-shadow: 0px 4px 10px 0px #cdcdcd;
          border: 10px solid #fcfcfc;
        }
        .news-mid-ti {
          width: 100%;
          box-sizing: border-box;
          padding: 0 10px;
          .news-mid-ti-title {
            font-size: 30px;
            color: #1a1a1a;
            line-height: 44px;
            margin-top: 30px;
            margin-bottom: 18px;
            text-align: left;
          }
          .news-mid-ti-zhai {
            font-size: 18px;
            color: #666;
            line-height: 28px;
            text-align: left;
          }
        }
      }
    }
    .work-bottom {
      width: 100%;
      height: 228px;
      overflow: hidden;
      .work-bottom-more {
        width: 150px;
        height: 44px;
        border-radius: 4px;
        font-size: 16px;
        color: #fff;
        line-height: 44px;
        text-align: center;
        margin: 84px auto;
        background: #3067c9;
        cursor: pointer;
      }
    }
  }
}
// 税务规划
.tax {
  width: 100%;
  height: 326px;
  background: #3067c9;
  position: relative;
  .tax-con {
    width: 954px;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    .tax-con-title {
      margin: 95px auto 38px;
      font-size: 36px;
      color: #fff;
      font-weight: bold;
      line-height: 44px;
    }
    .tax-con-content {
      width: 100%;
      font-size: 18px;
      color: #fff;
      line-height: 28px;
    }
  }
}
// 营业执照
.license {
  width: 100%;
  height: 428px;
  display: flex;
  justify-content: center;
  background: #fff;
  .license-box {
    width: 560px;
    height: 316px;
    background: #fafafa;
    margin: 22px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
// 主要服务对象
.main {
  width: 100%;
  height: 1286px;
  background: #fff;
  .main-con {
    width: 1080px;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    .main-title {
      font-size: 36px;
      color: #1a1a1a;
      font-weight: bold;
      line-height: 44px;
      margin: 95px auto 37px;
    }
    .main-content {
      width: 954px;
      margin: 0 auto 36px;
      font-size: 18px;
      color: #666;
      line-height: 28px;
    }
    .main-btn {
      width: 100%;
      height: 140px;
      box-sizing: border-box;
      padding: 0 10px;
      margin-bottom: 20px;
      .main-btn-text {
        width: 170px;
        height: 50px;
        background: #fff;
        border-radius: 4px;
        border: 2px solid #3067c9;
        margin: 10px 19px;
        float: left;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: #3067c9;
        font-weight: bold;
        cursor: pointer;
      }
      .main-btn-text:hover {
        background: rgba($color: #3067c9, $alpha: 0.2);
      }
      .main-btn-text-active {
        background: #3067c9;
        color: #fff;
      }
    }
    .main-box {
      width: 1070px;
      height: 740px;
      margin: 0 auto;
      box-sizing: border-box;
      box-shadow: 0px 4px 10px 0px #cdcdcd;
      padding: 30px 45px;
      .main-box-img {
        width: 980px;
        height: 500px;
        background: #fafafa;
        box-sizing: border-box;
        padding: 8px;
        .main-img {
          width: 100%;
          height: 100%;
        }
      }
      .main-bot {
        width: 980px;
        height: 140px;
        background: #fafafa;
        margin-top: 30px;
        overflow: hidden;
        box-sizing: border-box;
        padding: 0 13px;
        .main-box-text {
          font-size: 24px;
          color: #3067c9;
          font-weight: bold;
          line-height: 28px;
          margin: 23px auto 17px;
        }
        .main-box-con {
          font-size: 18px;
          color: #666;
          line-height: 28px;
        }
      }
    }
  }
}
// 系统工具
.tool {
  width: 100%;
  height: 905px;
  background: #fcfcfc;
  overflow: hidden;
  min-width: 1200px;
  /deep/.el-carousel__arrow {
    display: none !important;
  }
  /deep/.el-carousel__indicators--horizontal {
    display: none;
  }
  .tool-title {
    width: 100%;
    font-size: 36px;
    color: #1a1a1a;
    line-height: 44px;
    font-weight: bold;
    margin: 71px auto 40px;
  }
  .tool-tabs {
    border-bottom: 2px solid #e4e7ed;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tool-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 100px;
    .tool-btn {
      width: 200px;
      height: 48px;
      background: #3067c9;
      border-radius: 4px;
      font-size: 16px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 68px;
      cursor: pointer;
    }
  }
}
// 开启用工标准
.open {
  width: 100%;
  height: 574px;
  background: #fff;
  .open-con {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    .open-title {
      font-weight: bold;
      font-size: 36px;
      color: #1a1a1a;
      line-height: 44px;
      margin: 95px auto 60px;
    }
    .open-cont {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .open-item {
        width: 378px;
        .open-item-title {
          font-size: 30px;
          color: #3067c9;
          line-height: 28px;
          font-weight: bold;
          margin-bottom: 28px;
        }
        .open-item-content {
          font-size: 18px;
          color: #666;
          line-height: 28px;
          text-align: left;
        }
      }
    }
  }
}
// 企业介绍
.enterprise {
  width: 100%;
  height: 1040px;
  background: #fcfcfc;
  overflow: hidden;
  min-width: 1200px;
  position: relative;
  .enterprise-title {
    font-size: 36px;
    color: #1a1a1a;
    line-height: 44px;
    font-weight: bold;
    margin: 70px auto 40px;
  }

  .enterprise-tab {
    border-bottom: 2px solid #e4e7ed;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .enterprise-content {
    width: 1000px;
    box-sizing: border-box;
    padding: 40px 0;
    margin: 0 auto;
    .enterprise-zhai {
      width: 100%;
      font-size: 18px;
      color: #666;
      line-height: 28px;
      text-align: left;
      margin-top: 27px;
    }
  }
}
// 预约咨询
.consult {
  width: 100%;
  height: 1050px;
  background: #fff;
  min-width: 1200px;
  overflow: hidden;
  position: relative;
  .consult-con {
    width: 1280px;
    height: 810px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin: 100px auto;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0 110px;
    .consult-title {
      font-size: 36px;
      color: #1a1a1a;
      font-weight: bold;
      line-height: 44px;
      margin: 70px auto 40px;
    }
    .line {
      width: 100%;
      display: flex;
      .line-btn {
        width: 170px;
        height: 50px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
        font-weight: bold;
        color: #333;
        line-height: 50px;
        text-align: center;
        margin-right: 8px;
        cursor: pointer;
        box-sizing: border-box;
      }
      .lin-btn-active {
        background: #3067c9;
        color: #fff;
        border-color: #3067c9;
      }
    }
    .line-btns {
      width: 170px;
      height: 50px;
      background: #3067c9;
      font-size: 16px;
      font-weight: bold;
      margin-top: 30px;
      border-radius: 4px;
    }
  }
}
/deep/.el-form-item__label {
  width: 100%;
}
/deep/.el-radio-group {
  width: 100%;
  text-align: left;
}

// tabs
/deep/ .el-tabs__header {
  margin: 0 !important;
}
/deep/ .el-tabs__content {
  display: none;
}
/deep/ .el-tabs__item {
  height: 74px !important;
  line-height: 74px;
  font-size: 20px;
  color: #818181;
  padding: 0 28px !important;
}
/deep/.el-tabs__nav-wrap::after {
  height: 0 !important;
}
/deep/.el-tabs__active-bar{
  background: #3067c9;
}
/deep/.el-tabs__item.is-active{
  color: #3067c9;
}
/deep/.el-form--label-top .el-form-item__label{
  padding: 0px !important;
}
</style>
