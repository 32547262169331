<template>
  <div class="page">
    <div class="x-off-top">
      <!-- logo -->
      <div class="x-off-img">
        <el-image
          style="width: 324px; height: 60px"
          :src="require('@/assets/logo.png')"
          fit="contain"
        ></el-image>
      </div>
      <!-- 导航 -->
      <div class="x-off-nav">
        <span @click="handleClick('/#banner')">首页</span>
        <span @click="handleClick('/#news')">行业新闻</span>
        <span @click="handleClick('/#tax')">税务规划服务</span>
        <span @click="handleClick('/#enterprise')">关于税务规划</span>
        <span @click="handleClick('/#consult')">预约咨询</span>
      </div>
      <div class="x-off-right"></div>
    </div>
    <div class="detail">
      <div class="detail-title">{{ info.title }}</div>
      <div class="detail-time">{{ info.createtime }}</div>
      <div class="detail-content" v-html="info.content"></div>
    </div>
    <div class="x-off-footer">
      <div class="x-off-tp">
        <div class="x-off-tp-con">
          <div class="footer-logo">
            <el-image
              style="width: 348px; height: 68px"
              :src="require('@/assets/logo_bottom.png')"
            ></el-image>
          </div>
          <div class="footer-box">
            <div class="footer-item">
              <span @click="handleClick('/#news')">行业新闻</span>
              <span @click="handleClick('/#tax')">税务规划服务</span>
              <span @click="handleClick('/#enterprise')">关于税务规划</span>
              <span @click="handleClick('/#consult')">预约咨询</span>
            </div>
            <div class="footer-item">
              <span class="lian-us">联系我们</span>
              <div class="footer-item-box">
                <el-image
                  style="width: 35px; height: 32px"
                  :src="require('@/assets/icon_location.png')"
                ></el-image>
                <span class="footer-am">安徽省安庆市望江县经济开发区望江大道58号1号楼102室</span>
              </div>
              <div class="footer-item-box">
                <el-image
                  style="width: 32px; height: 32px"
                  :src="require('@/assets/icon_call.png')"
                ></el-image>
                <span class="footer-am">4006060390</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="x-off-bt">
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
          备案号：皖ICP备2024052700号-2</a
        >
      </div>
    </div>

    <!-- 提示 -->
    <div class="tips">
      <Tip2></Tip2>
    </div>
  </div>
</template>

<script>
import { articleInfo } from "@/api/index";
import Tip2 from "@/components/tip2";
export default {
  components: {
    Tip2,
  },
  data() {
    return {
      id: {},
      info: {},
    };
  },
  created() {
    const id = this.$route.query.id;
    this.id = id;
    this.getarticleInfo();
  },
  methods: {
    handleClick(tab) {
      const url = this.$router.resolve({ path: tab }).href;
      window.open(url, "_blank");
    },
    getarticleInfo() {
      articleInfo({
        article_id: this.id,
      }).then((res) => {
        if (res.code == 1) {
          this.info = res.data.info;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  background: #fff;
}
.x-off-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  height: 76px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  border-bottom: 1px solid #e8e8e8;
  .x-off-img {
    width: 320px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 42px;
    .x-off-title {
      font-size: 18px;
      color: #3067c9;
    }
  }
  .x-off-nav {
    flex-shrink: 0;
    flex: 1;
    min-width: 700px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      text-decoration: none;
      font-family: "Source Han Sans CN";
      font-size: 16px;
      color: #666666;
      line-height: 32px;
      display: block;
      height: 100%;
      box-sizing: border-box;
      margin: 0 28px !important;
      line-height: 76px;
      position: relative;
      cursor: pointer;
    }
    .active::after {
      content: "";
      display: block;
      width: 100%; /* 与父元素宽度相同 */
      height: 2px; /* 下划线的厚度 */
      background: #3067c9; /* 下划线的颜色 */
      position: absolute;
      bottom: 1px; /* 位于父元素的底部 */
      left: 0; /* 位于父元素的左侧 */
    }
  }
  .x-off-right {
    width: 320px;
  }
  .x-off-info {
    width: 250px;
    height: 100%;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    padding-right: 10px;
    .nav {
      padding: 30px;
      color: #aead2d;
      font-size: 14px;
      a {
        font-weight: bold;
        color: #aead2d;
        text-decoration: none;
        cursor: pointer;
      }
    }
    .top-bar__img {
      margin: 0 5px;
      padding: 2px;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      box-sizing: border-box;
      border: 1px solid #eee;
      vertical-align: middle;
      cursor: pointer;
    }
    .x-off-user {
      color: #fff;
    }
  }
}
/deep/.el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
  color: #fff !important;
}
.detail {
  width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  margin-top: 76px;
  .detail-title {
    margin-top: 40px;
    font-size: 24px;
    font-weight: 500;
    color: #333;
    margin-bottom: 8px;
    text-align: center;
  }
  .detail-time {
    font-size: 13px;
    color: #999;
    line-height: 16px;
    margin-bottom: 25px;
    text-align: left;
  }
}
.tips {
  position: fixed;
  right: 0px;
  bottom: 140px;
}

.x-off-footer {
  width: 100%;
  height: 100%;

  .x-off-tp {
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: space-between;
    background: #3067c9;
    .x-off-tp-con {
      width: 1100px;
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      .footer-logo {
        width: 100%;
        height: 68px;
        margin-top: 60px;
        margin-bottom: 29px;
        text-align: left;
      }
      .footer-box {
        width: 100%;
        box-sizing: border-box;
        padding-left: 84px;
        display: flex;
        .footer-item {
          width: 50%;
          display: flex;
          flex-direction: column;
          text-align: left;
          span {
            font-size: 18px;
            color: #fff;
            text-decoration: none;
            margin-bottom: 29px;
            cursor: pointer;
          }
          .lian-us {
            font-size: 18px;
            color: #fff;
            text-decoration: none;
            margin-bottom: 29px;
          }
          .footer-item-box {
            display: flex;
            align-items: center;
            margin-bottom: 17px;
            width: 100%;
            height: 40px;
            .footer-am {
              font-size: 16px;
              color: #fff;
              margin-left: 9px !important;
              margin-bottom: 0 !important;
              display: block;
              line-height: 40px;
              padding: 0 !important;
            }
          }
        }
      }
    }
  }
  .x-off-bt {
    width: 100%;
    height: 80px;
    background: #1849a2;
    font-size: 16px;
    color: #fff;
    line-height: 80px;
    text-align: center;
    a {
      font-size: 16px;
      color: #fff;
      text-decoration: none;
    }
  }
}
</style>
