<template>
  <div class="tips-box" :style="height >= 600 ? 'display: block' : 'display: none'">
    <span class="tips-item" style="margin-bottom: 6px" @click="handleClick('/#consult')">
      <el-image
        style="width: 34px; height: 31px"
        :src="require('@/assets/icon_zixun.png')"
        fit="contain"
      ></el-image>
      <span>预约咨询</span>
    </span>
    <div class="tips-item tips-wxs">
      <el-image
        style="width: 28px; height: 32px"
        :src="require('@/assets/icon_kefu.png')"
        fit="contain"
      ></el-image>
      <span>客服热线</span>
      <div class="tips-wx">
        <div class="tips-wx-content">
          <div class="tips-wx-inner">
            <div class="tips-wx-inner-content">
              <div class="tips-line">
                <div class="tips-line-title">客服热线</div>
                <div class="tips-line-phone">400-606-0390</div>
                <!-- <div class="tips-line-time">（人工客服：9:00-21:00，含法定节假日）</div> -->
              </div>
            </div>
          </div>
          <div class="tips-wx-arrow"></div>
        </div>
      </div>
    </div>
    <div class="tips-item tips-tops" @click="scrollToTop">
      <el-image
        style="width: 32px; height: 35px"
        :src="require('@/assets/icon_top.png')"
        fit="contain"
      ></el-image>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      height: 0
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleClick(tab) {
      const url = this.$router.resolve({ path: tab }).href;
      window.open(url, '_self');
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.height = scrollTop;
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>
<style lang="scss" scoped>
.tips-box {
  .tips-item {
    width: 82px;
    height: 82px;
    background: #3067c9;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 1px;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    span {
      color: #fff;
      font-size: 14px;
      line-height: 28px;
      margin-top: px;
    }
    .tips-wx {
      display: none;
      position: absolute;
      right: 100px;
      top: 0px;
      .tips-wx-content {
        position: relative;
        .tips-wx-arrow {
          width: 20px;
          height: 20px;
          &::before {
            position: absolute;
            top: 44px;
            right: -10px;
            width: 20px;
            height: 20px;
            transform: translateY(-50%) rotate(45deg);
            background: linear-gradient(270deg, #fff 50%, #fff 0) no-repeat -10px -10px;
            content: "";
          }
        }
        .tips-wx-inner {
          background-color: #fff;
          background-clip: padding-box;
          border-radius: 8px;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 16px 0px;
          .tips-wx-inner-content {
            padding: 12px 16px;
            color: rgba(0, 0, 0, 0.85);
            .tips-line {
              width: 245px;
              font-size: 13px;
              font-weight: 400;
              color: #999;
              line-height: 18px;
              .tips-line-title {
                font-size: 13px;
                text-align: left;
                margin-top: 3px;
              }
              .tips-line-phone {
                font-size: 16px;
                font-weight: 600;
                color: #e6231f;
                line-height: 22px;
                letter-spacing: 4px;
                text-align: left;
                margin-top: 3px;
              }
              .tips-line-time {
                font-size: 12px;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
  .tips-tops {
    box-sizing: border-box;
    background: #fff;
    border: 2px solid #3067c9;
    margin-top: 20px;
  }
  // .tips-tops:hover{
  //   background: rgba($color: #3067c9, $alpha: 0.2);
  // }
  .tips-wxs:hover {
    .tips-wx {
      display: block;
    }
  }
}
</style>
