/**
 * 判断是否为空
 */
export function validatenull(val) {
  if (typeof val == 'boolean') {
      return false;
  }
  if (typeof val == 'number') {
      return false;
  }
  if (val instanceof Array) {
      if (val.length == 0) return true;
  } else if (val instanceof Object) {
      if (JSON.stringify(val) === '{}') return true;
  } else {
      if (val == 'null' || val == null || val == 'undefined' || val == undefined || val == '') return true;
      return false;
  }
  return false;
}

export const getTomorrowAndDayAfterTomorrow = () => {
  const today = new Date(); // 获取当前日期
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1); // 获取明天的日期
  const dayAfterTomorrow = new Date(today);
  dayAfterTomorrow.setDate(today.getDate() + 2); // 获取后天的日期

  const formatDate = (date) => {
    const month = date.getMonth() + 1; // getMonth() 返回的月份是从0开始的
    const day = date.getDate();
    return {
      date: `${month}月${day}日`
    };
  };

  return [
    formatDate(tomorrow), // 格式化明天的日期
    formatDate(dayAfterTomorrow) // 格式化后天的日期
  ];
}