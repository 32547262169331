import Vue from 'vue';
import VueRouter from 'vue-router';

import Index from '@/views/official/index'; // 官网首页-框架

import Home from '@/views/home/index'; // 首页
import News from '@/views/news/list'; // 新闻
import Detail from '@/views/news/detail'; // 新闻
Vue.use(VueRouter);

const routes = [
  {
    path: '',
    component: Index,
    children:[
      {
        path: '/',
        name: 'Home',
        component: Home,
        meta:{
          title: '安庆中嘉网络科技有限公司'
        }
      }
    ]
  },
  {
    path: '/news',
    name: 'News',
    component: News,
    meta:{
      title: '资讯列表'
    }
  },{
    path: '/detail',
    name: 'Detail',
    component: Detail,
    meta:{
      title: '资讯详情'
    }
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  // 使用$nextTick确保DOM更新完成
  Vue.nextTick(() => {
    const hash = to.hash;
    if (hash) {
      setTimeout(() => {
      const element = document.querySelector(hash);
        if (element) {
          console.log(111)
          element.scrollIntoView();
        }
      }, 200);     
    }
  });
});

export default router;
