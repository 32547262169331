<template>
  <el-container>
    <el-header>
      <Top></Top>
    </el-header>
    <el-main>
      <router-view/>
    </el-main>
    <el-footer>
      <Footer></Footer>
    </el-footer>
    <!-- 提示 -->
    <div class="tips">
      <Tip></Tip>
    </div>
  </el-container>
</template>

<script>
import Top from '@/views/official/top';
import Footer from '@/views/official/footer';
import Tip from '@/components/tip'
export default {
  components: {
    Top,
    Footer,
    Tip
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang='scss' scoped>
  .el-container{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  .el-header {
    height: 76px !important;
    padding: 0 !important;
    flex-shrink: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1200;
    background: #fff;
    width: 100%;
  }
  .el-footer {
    height: 480px !important;
    padding: 0 !important;
    flex-shrink: 0;
  }
  .el-main {
    background-color: #fff;
    color: #333;
    text-align: center;
    padding: 0 !important;
    flex: 1;
    margin-top: 76px;
  }
  .tips{
    position: fixed;
    right: 0px;
    bottom: 140px;
    z-index: 1940;
  }
</style>
