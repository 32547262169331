<template>
  <div class="x-off-top">
    <!-- logo -->
    <div class="x-off-img">
      <el-image
        style="width: 324px; height: 60px"
        :src="require('@/assets/logo.png')"
        fit="contain"
      ></el-image>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick" class="tab-box" ref="tabs">
      <el-tab-pane label="首页" name="/#banner"></el-tab-pane>
      <el-tab-pane label="行业新闻" name="/#news"></el-tab-pane>
      <el-tab-pane label="税务规划服务" name="/#tax"></el-tab-pane>
      <el-tab-pane label="关于税务规划" name="/#enterprise"></el-tab-pane>
      <el-tab-pane label="预约咨询" name="/#consult"></el-tab-pane>
    </el-tabs>
    <div class="x-off-right"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: "/#banner",
    };
  },
  watch: {
    // 使用字符串形式监听$route的变化
    "$route.hash"(newHash, oldHash) {
      this.activeName = newHash ? `/${newHash}` : "/#banner";
    },
  },
  created() {
    this.activeName = this.$route.hash ? `/${this.$route.hash}` : "/#banner";    
  },
  methods: {
    handleClick(tab, event) {
      const url = this.$router.resolve({ path: tab.name }).href;
      window.open(url, '_self');
    },
  },
};
</script>
<style lang="scss" scoped>
.x-off-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  height: 100%;
  .x-off-img {
    width: 320px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    box-sizing: border-box;
    padding-left: 42px;
    .x-off-title {
      font-size: 18px;
      color: #3067c9;
    }
  }
  .x-off-right {
    width: 320px;
  }
}
// tabs
.tab-box {
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
/deep/ .el-tabs__header {
  margin: 0 !important;
}
/deep/ .el-tabs__content {
  display: none;
}
/deep/ .el-tabs__item {
  height: 74px !important;
  line-height: 74px;
  font-size: 16px;
  color: #666666;
  padding: 0 28px !important;
}
/deep/.el-tabs__nav-wrap::after{
  height: 0 !important;
}
/deep/.el-tabs__active-bar{
  background: #3067c9;
}
/deep/.el-tabs__item.is-active{
  color: #3067c9;
}
</style>
